import { ActionReducerMap } from '@ngrx/store';

import { IUserFeatureState } from './interfaces';
import * as fromEntity from './user.reducer';

export * from './user.messages';

export const reducers: ActionReducerMap<IUserFeatureState> = {
  entityState: fromEntity.reducer,
};
